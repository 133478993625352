import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Dialog } from 'primereact/dialog'
import { Icon } from './Icon'
import { getHost } from '@Common/Services'
import { backgrounds } from '../Resources/Backgrounds'
import { Text, Button } from '.'

class ModalGeneric extends Component {
  constructor (props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount () {
    document.getElementById('modal-root').appendChild(this.el)
  }

  componentWillUnmount () {
    document.getElementById('modal-root').removeChild(this.el)
  }

  render () {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}
class Modal extends Component {
  constructor (props) {
    super(props)
    this.state = { visible: false }
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
  }

  componentDidMount () {
    this.props.setShow && this.props.setShow(this.show)
    this.props.setHide && this.props.setHide(this.hide)
  }

  show () {
    this.setState({ visible: true })
  }

  hide () {
    this.setState({ visible: false })
  }

  polimorphicContent () {
    return (
      <>
        {typeof this.props.content === 'function'
          ? this.props.content
          : typeof this.props.content === 'object'
            ? this.props.content
            : (
              <Text
                style={{
                  ...this.props.style,
                  font: 'bold',
                  fontSize: '20px',
                  marginBottom: '20',
                  marginRight: 20,
                  ...centerHorizontally
                }}
                value={this.props.content}
              />)}

        {this.props.action && (
          <>
            <div
              className='action-buttons'
              style={{ marginBottom: '30px', marginTop: 20, ...centerHorizontally }}
              onClick={(event) => event.stopPropagation()}
            >
              {!this.props.onlyYes && (
                <Button
                  rich
                  label={this.props.no || 'No'}
                  icon='close'
                  onClick={this.hide}
                  style={{ marginRight: 20 }} // width: 100
                />
              )}
              <Button
                rich
                label={this.props.yes || 'Ok'}
                icon='check'
                style={{}} // width: 100
                onClick={() => {
                  !this.props.noHideOnAction && this.hide()
                  this.props.action()
                }}
              />
            </div>
          </>
        )}
      </>
    )
  }

  render () {
    return (
      <ModalGeneric>
        <Dialog
          modal
          dismissableMask={!this.props?.noDismissable}
          draggable
          showHeader={false}
          visible={this.state.visible}
          closable
          style={{
            minWidth: 550,
            backgroundImage: `url(${backgrounds[1]})` /* `url(${imgDialog})` */,
            backgroundSize: 'cover',
            borderRadius: '20px',
            overflow: 'auto',
            overflowX: 'hidden',
            scrollbarWidth: 'none',
            ...this.props.style
          }}
          onHide={this.hide}
        >
          <div
            onClick={(event) => event.stopPropagation()}
            className='contentContainer'
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              backgroundImage: `url(${backgrounds[1]})`,
              backgroundSize: 'cover',
              borderRadius: '20px',
              color: getHost()[0].theme === 1 ? 'white' : '#32324e',
              boxShadow: '0px 0px 57px -25px rgba(0,0,0,0.75)',
              height: '100%',
              padding: 'inherit',
              ...this.props.styleContainer
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
              {!this.props.hideClose &&

                <Icon
                  color={getHost()[0].theme === 1 ? 'white' : '#32324e'}
                  name='close'
                  size={20}
                  style={{ cursor: 'pointer', marginBottom: 10 }}
                  onClick={() => this.hide()}
                />}
            </div>
            <Text
              style={{
                color: getHost()[0].theme === 1 ? 'white' : '#32324e',
                ...centerHorizontally,
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 20
              }}
              size={20}
              value={this.props.header}
            />
            {this.polimorphicContent()}
          </div>
        </Dialog>
      </ModalGeneric>
    )
  }
}

const centerHorizontally = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 20
}

export { Modal }
