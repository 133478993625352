import React from 'react'
import { motion } from 'framer-motion'

const Hover = ({ style, children, rotateAnimation = 0 }) => {
  return (
    <motion.div
      style={{ cursor: 'pointer', ...style }}
      whileHover={{ scale: 1.2, rotate: rotateAnimation }}
    >
      {children}
    </motion.div>
  )
}
export default Hover
